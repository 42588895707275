
import Vue from 'vue';
import FeaturesGridCard from '@/modules/index-page/components/FeaturesGridCard.vue';
import { LocDivider } from '@localazy/components';

export default Vue.extend({
  name: 'FeaturesGrid',
  components: {
    FeaturesGridCard,
    LocDivider
  }
});
