
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { LocCarousel } from '@localazy/components';
import IBlogPost, { IBlogUser } from '@localazy/components/dist/modules/ghost/models/blog-post';
import ITag from '@localazy/components/dist/modules/ghost/models/tag';
import trimTimeFromDateTimeString from '@/functions/trim-time-from-datetime-string';
import ArticleTag from '@/modules/@common/components/ArticleTag.vue';

const fallbackImageUrl = 'bc1db540-c443-47b6-aef5-96efe73d4f86';
const fallbackAvatarId = '15f771c5-9088-4ba8-ae7f-9406c3b470c9';

interface IData {
  swiperOptions: Record<string, unknown>;
}

export default Vue.extend({
  name: 'BlogCard',
  components: {
    ArticleTag,
    LocCarousel
  },
  props: {
    post: {
      type: Object as Prop<IBlogPost>,
      required: true
    },
    image: {
      type: String,
      default: fallbackImageUrl
    },
    featured: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 4,
        autoplay: false,
        mousewheel: {
          enabled: true,
          sensitivity: 2,
          releaseOnEdges: false
        },
        observer: true,
        observeParents: true
      }
    };
  },
  computed: {
    hasImage(): boolean {
      return !!this.post.main_image;
    },
    authorHasAvatar(): boolean {
      return !!this.author.avatar;
    },
    imageUrl (): string {
      return this.post.main_image
        ? this.post.main_image
        : `https://directus9.localazy.com/assets/${fallbackImageUrl}`;
    },
    backgroundImageUrl(): string {
      return this.$img(this.imageUrl, { width: 900, quality: 80, format: 'webp' });
    },
    createdDate (): string {
      if (this.post.created_on) {
        return trimTimeFromDateTimeString(this.post.created_on);
      }
      return '';
    },
    author (): IBlogUser {
      if (this.post) {
        return this.post.created_by || this.post.owner;
      }
      return {
        first_name: '',
        id: 0,
        last_name: ''
      };
    },
    authorAvatarUrl (): string {
      return this.author.avatar
        ? this.author.avatar
        : fallbackAvatarId;
    },
    postTags (): ITag[] {
      const tags = [...this.post.tags];
      return tags.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    }
  }
});
