
import Vue from 'vue';
import { LocButton } from '@localazy/components';

const SvgCliDevs = () => import('@/assets/svg/SvgCliDevs.vue');

export default Vue.extend({
  name: 'DeveloperFriendly',
  components: {
    LocButton,
    SvgCliDevs
  }
});
