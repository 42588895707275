
import Vue from 'vue';
import FeaturedBrands from '@/modules/featured-brands/components/FeaturedBrands.vue';
import { Brand } from '@localazy/directus-service/lib/@types';
import { FeaturedBrandsService } from '@localazy/directus-service';

interface IData {
  brands: Brand[];
}

export default Vue.extend({
  name: 'FeaturedBrandsContainer',
  components: {
    FeaturedBrands
  },
  props: {
    noFooter: {
      type: Boolean,
      default: false
    }
  },
  async fetch () {
    this.brands = await FeaturedBrandsService.fetchAllFeaturedBrands();
  },
  data (): IData {
    return {
      brands: []
    };
  }
});
