
import Vue from 'vue';
import { LocIcon } from '@localazy/components';

const cssVar = (value: string) => `var(--${value})`;

export default Vue.extend({
  name: 'FeatureCard',
  components: {
    LocIcon
  },
  props: {
    color: {
      type: String,
      default: 'accent'
    },
    cardLink: {
      type: String,
      default: '/features/automated-workflows'
    },
    cardImage: {
      type: String,
      default: '05f53755-e7d5-4594-b979-79bb125594f1'
    },
    cardTitle: {
      type: String,
      default: 'Automated Workflows'
    },
    cardDescription: {
      type: String,
      default: 'Set up automated workflows that perform selected actions triggered by your rules. Automate repetitive tasks and spend less time on the tedious parts of translation management.'
    },
    secondaryLabel: {
      type: String,
      default: 'See pricing'
    },
    imgPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor(): string {
      if (this.color === 'accent') {
        return cssVar(`${this.color}-lighten-4`);
      }
      return cssVar(`${this.color}-lighten-5`);
    }
  }
});
