import { render, staticRenderFns } from "./AdvancedFeaturesCarousel.vue?vue&type=template&id=11dc0fc1&scoped=true"
import script from "./AdvancedFeaturesCarousel.vue?vue&type=script&lang=ts"
export * from "./AdvancedFeaturesCarousel.vue?vue&type=script&lang=ts"
import style0 from "./AdvancedFeaturesCarousel.vue?vue&type=style&index=0&id=11dc0fc1&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11dc0fc1",
  null
  
)

export default component.exports