/* eslint-disable quotes */
import { VideoObject } from 'schema-dts';
import CorporationLocalazy from '@/modules/@common/structured-data/corporation_localazy';

export default (): VideoObject => {
  return {
    '@type': 'VideoObject',
    '@id': 'https://video.localazy.com/AppBoostCLI.mp4',
    duration: 'PT1M11S',
    name: "What is Localazy's CLI?",
    thumbnailUrl: 'https://directus9.localazy.com/assets/80144d24-fd88-4ac0-a2f6-3ad71667f307',
    contentUrl: 'https://video.localazy.com/AppBoostCLI.mp4',
    uploadDate: '2021-07-07',
    description: "An introduction into Localazy's CLI",
    transcript: "There are over 4 billion people online behind language barriers. Unlock the global potential of your app and avoid the painful localization process with Localazy. Integrate Localazy within minutes and enjoy automated localization workflow. Say no to old-fashioned file handling. Invest your time into the development of your app and translate it quickly with Localazy. Translate your app with our shared translations for free. Invite your users to help or let our Virtual Translator take care of everything for you. Localazy will manage your translations, provide an interface for contributors, detect changes in your texts, notify translators and help you achieve quality translations, all within a single platform. Make your app global and speak everyone's language. Start today and try Localazy for free at localazy.com.",
    creator: CorporationLocalazy()
  };
};
