
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { Persona } from '@localazy/directus-service/lib/@types';
import { LocShowcase } from '@localazy/components';
import ShowcaseItem from '@localazy/components/dist/modules/@core/components/LocShowcase/showcase-item';
import CaseStudyPersonasCard from '@/modules/case-study-landing-page/components/CaseStudyPersonasCard.vue';

export default Vue.extend({
  name: 'CaseStudyPersonasTab',
  components: {
    LocShowcase,
    CaseStudyPersonasCard
  },
  props: {
    roles: {
      type: Array as Prop<Persona[]>,
      required: true
    },
    industries: {
      type: Array as Prop<Persona[]>,
      required: true
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buildRoleLink (role: Persona): string {
      return `/for/${role.slug}`;
    },
    buildIndustryLink (industry: Persona): string {
      return `/for/${industry.slug}`;
    }
  },
  computed: {
    showcaseItems(): ShowcaseItem[] {
      return [
        { title: 'Roles' },
        { title: 'Industry' }
      ];
    }
  }
});
