
import Vue, { PropType } from 'vue';
import HeaderDivider from '@/modules/@common/components/HeaderDivider.vue';
import BadgesHolder from '@/modules/@common/components/badges/BadgesHolder.vue';
import { Homepage } from '@localazy/directus-service/lib/@types';

export default Vue.extend({
  name: 'BadgesSlider',
  components: {
    HeaderDivider,
    BadgesHolder
  },
  props: {
    headerText: {
      type: String,
      default: ''
    },
    footerText: {
      type: String,
      default: ''
    },
    footerLink: {
      type: String,
      default: ''
    },
    homepage: {
      type: Object as PropType<Homepage>,
      required: true
    }
  }
});
