
import Vue from 'vue';
import { LocCarousel, LocButton } from '@localazy/components';
import KeepSane from '@/assets/svg/scribbles/KeepSane.vue';
import FeatureCard from '@/modules/@common/components/carousels/FeatureCard.vue';

interface IData {
  swiperOptions: Record<string, unknown>;
}

export default Vue.extend({
  name: 'AdvancedFeaturesCarousel',
  components: {
    LocCarousel,
    LocButton,
    KeepSane,
    FeatureCard
  },
  data(): IData {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 24,
        autoplay: false,
        navigation: {
          enabled: true,
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
          disabledClass: 'disable-btn'
        },
        observer: true,
        observeParents: true
      }
    };
  }
});
