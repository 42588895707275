
import Vue from 'vue';
import { LocIcon } from '@localazy/components';
export default Vue.extend({
  name: 'CaseStudyPersonasCard',
  components: {
    LocIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  }
});
