
import Vue from 'vue';
import { LocCarousel, LocButton } from '@localazy/components';
import CaseStudySlide from '@/modules/@common/components/carousels/CaseStudySlide.vue';

interface IData {
  swiperOptions: Record<string, unknown>;
}

const cssVar = (value: string) => `var(--${value})`;

export default Vue.extend({
  name: 'CaseStudyCarousel',
  components: {
    CaseStudySlide,
    LocCarousel,
    LocButton
  },
  data(): IData {
    return {
      swiperOptions: {
        slidesPerView: '1',
        autoplay: true,
        loop: true,
        speed: 1600,
        spaceBetween: 24,
        navigation: {
          enabled: true,
          nextEl: '.next-btn',
          prevEl: '.prev-btn'
        },
        observer: true,
        observeParents: true
      }
    };
  },
  props: {
    color: {
      type: String,
      default: 'accent'
    }
  },
  computed: {
    bgColor(): string {
      return cssVar(`${this.color}-lighten-5`);
    },
    hoverColor(): string {
      return cssVar(`${this.color}-lighten-4`);
    }
  }
});
