
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { PersonasTab } from '@localazy/directus-service/lib/@types';
import CaseStudyPersonasTab from '@/modules/case-study-landing-page/components/CaseStudyPersonasTab.vue';
import { PersonasTabService } from '@localazy/directus-service';

interface IData {
  personasTab: PersonasTab;
}

export default Vue.extend({
  name: 'PersonasTabContainer',
  components: {
    CaseStudyPersonasTab
  },
  props: {
    personasSlug: {
      type: String as Prop<string>,
      default: 'default'
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      personasTab: {
        id: 0,
        slug: '',
        status: 'draft',
        roles: [],
        industries: []
      }
    };
  },
  async fetch () {
    this.personasTab = await PersonasTabService.fetchPersonasTab(this.personasSlug);
  }
});
