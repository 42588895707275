
import Vue from 'vue';
import { LocEmoji } from '@localazy/components';
import BestFeedbackMembers from '@/modules/index-page/components/BestFeedbackMembers.vue';

export default Vue.extend({
  name: 'BestFeedback',
  components: {
    LocEmoji,
    BestFeedbackMembers
  }
});
