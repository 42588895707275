
import Vue from 'vue';
import { LocButton, LocIcon } from '@localazy/components';
import UiOverview from '@/modules/index-page/components/UiOverview.vue';
import HeroSectionText from '@/modules/index-page/components/HeroSectionText.vue';

export default Vue.extend({
  name: 'HeroSection',
  components: {
    LocButton,
    LocIcon,
    UiOverview,
    HeroSectionText
  }
});
