
import Vue from 'vue';
import { LocEmoji } from '@localazy/components';

export default Vue.extend({
  name: 'HeroSectionText',
  components: {
    LocEmoji
  }
});
