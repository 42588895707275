import gsap from 'gsap';

export default (animation: gsap.core.Animation, selector: string): IntersectionObserver => {
  const element = document.querySelector(selector);
  if (element) {
    const handleIntersect = (entry: IntersectionObserverEntry[]) => {
      entry.forEach((element: IntersectionObserverEntry) => {
        if (element.isIntersecting) {
          animation.play();
        } else {
          animation.pause();
        }
      });
    };
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 1]
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(element);
    return observer;
  } else {
    throw new Error(`Intersection element not found ${selector}`);
  }
};
