var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header-divider w-full flex items-center"},[_c('div',{staticClass:"h-two flex-1",class:{
      'bg-accent-lighten-4' : !_vm.grey,
      'bg-grey-lighten-4' : _vm.grey
    }}),_vm._v(" "),_c('h2',{staticClass:"text-secondary text-center inline-block px-3 lg:px-4"},[_vm._t("default",function(){return [_vm._v("\n      Featured Apps\n    ")]})],2),_vm._v(" "),_c('div',{staticClass:"h-two flex-1",class:{
      'bg-accent-lighten-4' : !_vm.grey,
      'bg-grey-lighten-4' : _vm.grey
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }