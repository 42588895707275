
import Vue, { PropType } from 'vue';
import { Homepage } from '@localazy/directus-service/lib/@types';

export default Vue.extend({
  name: 'BadgesHolder',
  props: {
    homepage: {
      type: Object as PropType<Homepage>,
      required: true
    }
  }
});
