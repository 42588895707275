
import Vue from 'vue';
import { LocButton, LocIcon } from '@localazy/components';
const cssVar = (value: string) => `var(--${value})`;

export default Vue.extend({
  name: 'CaseStudySlide',
  components: {
    LocButton,
    LocIcon
  },
  props: {
    color: {
      type: String,
      default: 'accent'
    },
    quote: {
      type: String,
      default: 'We enjoy combining our in-house people, automated professional translations, and crowdsourcing the translations...'
    },
    author: {
      type: String,
      default: 'Lukáš Tóth'
    },
    position: {
      type: String,
      default: 'Product Manager at Rouvy'
    },
    link: {
      type: String,
      default: '/case-study/rouvy'
    },
    linkedin: {
      type: String,
      default: '/'
    },
    photo: {
      type: String,
      default: '20b7f410-2631-481c-b5f1-3010717d2940'
    },
    logo: {
      type: String,
      default: 'f5605c13-f072-4207-9a54-b217299e2cc5'
    }
  },
  computed: {
    itemColor(): string {
      return cssVar(`${this.color}-lighten-4`);
    },
    socialsColor(): string {
      return cssVar(`${this.color}-darken-1`);
    }
  }
});
