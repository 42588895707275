
import Vue from 'vue';
import { LocShowcase, LocLazy, LocButton } from '@localazy/components';
import ShowcaseItem from '@localazy/components/dist/modules/@core/components/LocShowcase/showcase-item';
import LineZet from '@/assets/svg/scribbles/LineZet.vue';
import SvgCircleLines from '@/assets/svg/SvgCircleLines.vue';

const SvgTranslateCoop = () => import('@/assets/svg/your-way/SvgTranslateCoop.vue');
const SvgWorkflowsAi = () => import('@/assets/svg/your-way/SvgWorkflowsAi.vue');
const SvgTranslators = () => import('@/assets/svg/your-way/SvgTranslators.vue');
const SvgCustom = () => import('@/assets/svg/your-way/SvgCustom.vue');

export default Vue.extend({
  name: 'TwoLineShowcase',
  components: {
    LocShowcase,
    LocLazy,
    LocButton,
    LineZet,
    SvgCircleLines,
    SvgTranslateCoop,
    SvgWorkflowsAi,
    SvgTranslators,
    SvgCustom
  },
  computed: {
    showcaseItems(): ShowcaseItem[] {
      return [
        { title: 'Translate & Collaborate Together', subtitle: 'Invite your team, community, or clients to help you!' },
        { title: 'Harness Machine Translations & AI', subtitle: 'Add new languages in seconds to get a head start.' },
        { title: 'Cut Down On Micromanagement', subtitle: 'Get automated human professional translators for your project.' },
        { title: 'Choose Your Own Strategy', subtitle: 'Combine various methods into your unique localization strategy.' }
      ];
    }
  }
});
