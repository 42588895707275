
import Vue from 'vue';
import { LocButton, LocEmoji } from '@localazy/components';
import ArrowBottomDown from '@/assets/svg/scribbles/ArrowBottomDown.vue';
import { isExternalLink } from '@/modules/@common/functions/is-external-link';
const cssVar = (value: string) => `var(--${value})`;

export default Vue.extend({
  name: 'ThreeColsCta',
  components: {
    LocButton,
    LocEmoji,
    ArrowBottomDown
  },
  props: {
    color: {
      type: String,
      default: 'accent'
    },
    primaryLink: {
      type: String,
      default: '/register'
    },
    primaryLabel: {
      type: String,
      default: 'Start for free'
    },
    secondaryLink: {
      type: String,
      default: ''
    },
    secondaryLabel: {
      type: String,
      default: 'See pricing'
    },
    emoji1: {
      type: String,
      default: '👋'
    },
    emoji2: {
      type: String,
      default: '🎁'
    },
    emoji3: {
      type: String,
      default: '💗'
    },
    emoji4: {
      type: String,
      default: '🤑'
    },
    emoji5: {
      type: String,
      default: '👨‍💼'
    },
    emoji6: {
      type: String,
      default: '🏢'
    },
    twoRows: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor(): string {
      return cssVar(`${this.color}-lighten-5`);
    },
    borderColor(): string {
      return cssVar(`${this.color}-lighten-4`);
    }
  },
  methods: {
    isExternalLink
  }
});
