
import Vue from 'vue';
export default Vue.extend({
  name: 'HeaderDivider',
  props: {
    grey: {
      type: Boolean,
      default: false
    }
  }
});
