
import Vue from 'vue';
import { LocShowcase, LocButton, LocLazy } from '@localazy/components';
import ShowcaseItem from '@localazy/components/dist/modules/@core/components/LocShowcase/showcase-item';
import ScratchesDense from '@/assets/svg/scribbles/ScratchesDense.vue';
import Translate from '@/assets/svg/scribbles/text/Translate.vue';
import SvgStripeFill from '@/assets/svg/SvgStripeFill.vue';

const SvgDev = () => import('@/assets/svg/integration/SvgDev.vue');
const SvgSdk = () => import('@/assets/svg/integration/SvgSdk.vue');
const SvgCms = () => import('@/assets/svg/integration/SvgCms.vue');
const SvgFigma = () => import('@/assets/svg/integration/SvgFigma.vue');
const SvgFormats = () => import('@/assets/svg/integration/SvgFormats.vue');

export default Vue.extend({
  name: 'IntegrationShowcase',
  components: {
    LocShowcase,
    LocButton,
    LocLazy,
    ScratchesDense,
    Translate,
    SvgStripeFill,
    SvgDev,
    SvgSdk,
    SvgCms,
    SvgFigma,
    SvgFormats
  },
  computed: {
    showcaseItems(): ShowcaseItem[] {
      return [
        { title: 'Software Development Frameworks' },
        { title: 'Mobile & Multiplatform Apps' },
        { title: 'Websites & CMS' },
        { title: 'Figma Designs' },
        { title: 'And 25+ File Formats' }
      ];
    }
  }
});
