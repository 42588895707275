
import Vue from 'vue';
export default Vue.extend({
  name: 'ArticleTag',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    bigger: {
      type: Boolean,
      default: false
    }
  }

});
