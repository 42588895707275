
import Vue from 'vue';
import { LocButton } from '@localazy/components';
import ScratchesDense from '@/assets/svg/scribbles/ScratchesDense.vue';
import Minutes from '@/assets/svg/scribbles/text/Minutes.vue';

const SvgPlanet = () => import('@/assets/svg/SvgPlanet.vue');

export default Vue.extend({
  name: 'UptodateTranslations',
  components: {
    LocButton,
    SvgPlanet,
    ScratchesDense,
    Minutes
  }
});
