import CorporationDTO from '@/modules/@common/structured-data/models/corporation-dto';

export default (): CorporationDTO => {
  return {
    '@type': 'Corporation',
    '@id': 'https://localazy.com#corporation', // unique identifier, does not need to exist even
    name: 'Localazy',
    legalName: 'Localazy, s.r.o.',
    email: 'team@localazy.com',
    url: 'https://localazy.com',
    description: 'Localazy is a technology-agnostic platform supporting 50+ frameworks, file formats & popular tools. Learn more. localization team on autopilot workflow automation. Integrate with your stack 50+ integrations and growing. Empower international growth Agile Localization Platform.',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Brno',
      addressCountry: 'CZ',
      postalCode: '602 00',
      streetAddress: 'Mlýnská 326/13'
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'Customer support',
      email: 'team@localazy.com',
      url: 'https://localazy.com/contact'
    },
    brand: {
      '@type': 'Brand',
      name: 'Localazy'
    },
    image: 'https://directus9.localazy.com/assets/3d660155-5d24-4e83-a3b8-e20ca1a37fbd',
    logo: 'https://directus9.localazy.com/assets/3d660155-5d24-4e83-a3b8-e20ca1a37fbd',
    sameAs: [
      'https://twitter.com/localazy',
      'https://www.linkedin.com/company/localazy',
      'https://www.facebook.com/localazy/',
      'https://www.g2.com/products/localazy/reviews',
      'https://www.capterra.com/p/204830/Localazy/',
      'https://sourceforge.net/software/product/Localazy/',
      'https://www.getapp.com/it-management-software/a/localazy/',
      'https://www.crunchbase.com/organization/localazy',
      'https://crozdesk.com/software/localazy',
      'https://www.instagram.com/localazycom/',
      'https://github.com/marketplace/localazy',
      'https://stackshare.io/localazy',
      'https://www.trustpilot.com/review/localazy.com'
    ]
  };
};
