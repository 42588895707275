var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rounded overflow-hidden transition-shadow duration-200 ease-out h-full w-full flex relative border-2 no-underline",class:{
    'bg-secondary-darken-4 text-white transform transition-transform scale-featured border-primary-darken-1': _vm.featured,
    'bg-white border-grey-lighten-3 hover:border-primary-lighten-4 hover:shadow-flat-sm lg:hover:shadow-flat ': !_vm.featured,
  }},[_c('nuxt-link',{staticClass:"hidden",class:{
      'sm:block sm:flex-1 border-r-2' : _vm.horizontal,
      'md:hidden': _vm.horizontal && !_vm.featured,
      'border-secondary-darken-3': _vm.featured,
      'border-grey-lighten-4': !_vm.featured,
    },attrs:{"to":`/blog/${_vm.post.slug}`}},[_c('nuxt-img',{staticClass:"img-horizontal h-full w-full bg-cover bg-center relative",attrs:{"src":_vm.imageUrl,"sizes":"sm:400 lg:700","loading":"lazy","preset":"default","alt":`Illustration of ${_vm.post.title}`}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col h-full flex-1 w-full",class:{
      'sm:h-auto': _vm.horizontal,
      'md:h-full': _vm.horizontal && !_vm.featured,
    }},[_c('nuxt-link',{staticClass:"flex-1 flex flex-col",attrs:{"to":`/blog/${_vm.post.slug}`}},[_c('div',{staticClass:"flex-1 px-4 pt-4 pb-3 text-lg border-b-2 leading-tight",class:{
          'border-secondary-darken-3 md:px-5 lg:px-6 lg:text-2xl xl:text-3xl text-white': _vm.featured,
          'text-secondary-darken-3 border-grey-lighten-4': !_vm.featured,
          'sm:border-b-0' : _vm.horizontal,
          'lg:border-b-2': _vm.horizontal && !_vm.featured,
          'md:text-xl' : !_vm.simple && !_vm.featured,
        }},[_vm._v("\n        "+_vm._s(_vm.post.title)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"w-full relative",class:{
          'sm:hidden': _vm.horizontal,
          'md:block': _vm.horizontal && !_vm.featured,
        }},[_c('nuxt-img',{staticClass:"w-full",attrs:{"src":_vm.imageUrl,"sizes":"sm:400 lg:700","loading":"lazy","preset":"default","alt":`Illustration of ${_vm.post.title}`}})],1)]),_vm._v(" "),_c('div',{staticClass:"items-center py-2",class:{
        'md:py-4 xl:py-6 xl:gap-x-4 border-secondary-darken-3': _vm.featured,
        'border-grey-lighten-4': !_vm.featured,
        'hidden' : _vm.simple,
        'flex border-t-2' : !_vm.simple
      }},[_c('nuxt-link',{staticClass:"flex-shrink-0 hidden rounded-full overflow-hidden bg-primary-lighten-4",class:{
          'md:ml-5 xl:ml-6 md:block border-2 border-secondary-darken-2 w-12 h-12 xl:w-16 xl:h-16': _vm.featured,
          'lg:ml-3 lg:block border border-primary-lighten-4 w-10 h-10': !_vm.featured

        },attrs:{"to":`/blog/author/${_vm.author.slug}`}},[_c('nuxt-img',{staticClass:"bg-cover bg-center w-full h-full transition-transform transform hover:scale-90 rounded-full",attrs:{"src":_vm.authorAvatarUrl,"loading":"lazy","width":"48","preset":"quality","alt":_vm.author.first_name}})],1),_vm._v(" "),_c('div',{staticClass:"w-full text-sm truncate relative"},[(!_vm.featured)?_c('div',{staticClass:"w-full absolute bottom-0 left-0 h-5 flex justify-between pointer-events-none z-10"},[_c('div',{staticClass:"h-full w-3 lg:w-2 bg-gradient-to-r from-white to-transparent"}),_vm._v(" "),_c('div',{staticClass:"h-full w-3 bg-gradient-to-l from-white to-transparent"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex truncate w-full px-3 lg:pl-2 items-center gap-1"},[_c('nuxt-link',{staticClass:"inline-block font-medium hover:underline truncate",class:{
              'text-secondary-darken-2 hover:text-secondary-darken-2': !_vm.featured,
              'md:text-base xl:text-lg text-white hover:text-white': _vm.featured
            },attrs:{"to":`/blog/author/${_vm.author.slug}`}},[_vm._v("\n            "+_vm._s(_vm.author.first_name)+"\n            "+_vm._s(_vm.author.last_name)+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"flex-shrink-0",class:{
              'text-secondary-lighten-3 xl:text-base': _vm.featured,
              'text-grey': !_vm.featured
            }},[_vm._v("\n            ·\n            "+_vm._s(_vm.createdDate)+"\n          ")])],1),_vm._v(" "),_c('loc-carousel',{staticClass:"!px-3 lg:!pl-2",attrs:{"options":_vm.swiperOptions,"slide-classes":"!w-fit-content"}},_vm._l((_vm.postTags),function(tag){return _c('nuxt-link',{key:tag.id,staticClass:"inline-block",attrs:{"to":`/tags/${tag.slug}`}},[_c('article-tag',{staticClass:"leading-none",attrs:{"dark":_vm.featured}},[_vm._v("\n              #"+_vm._s(tag.label)+"\n            ")])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }