var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col gap-x-10 gap-y-6",class:{'md:flex-row md:items-start' : !_vm.column }},[_c('div',{staticClass:"card-image w-full rounded border-2 relative max-h-96 md:max-h-none",class:{
      'md:order-2' : _vm.inverse,
      'md:h-full' : _vm.column,
      'p-6' : _vm.padding,
    },style:({
      '--card-color': _vm.cardColor,
    })},[_c('div',{staticClass:"absolute rounded-full border-2 border-primary-lighten-4 flex items-center justify-center bg-white w-14 h-14 lg:w-16 lg:h-16 transform",class:{
        '-mt-4 lg:-mt-6 top-0' : !_vm.iconBottom,
        '-mb-4 lg:-mb-6 bottom-0' : _vm.iconBottom,
        '-ml-4 lg:-ml-6 left-0 rotate-12' : !_vm.iconRight,
        '-mr-4 lg:-mr-6 right-0 -rotate-12' : _vm.iconRight,
      }},[_c('span',{staticClass:"text-3xl lg:text-4xl emoji-anim"},[_c('loc-emoji',{attrs:{"emoji":_vm.emoji}})],1)]),_vm._v(" "),_c('nuxt-img',{staticClass:"w-full h-full object-contain",attrs:{"src":_vm.image,"provider":"directus","alt":"Image of this feature","quality":"90"}})],1),_vm._v(" "),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight mb-2"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-y-2 text-lg lg:text-xl"},[_vm._t("default",function(){return [_c('p',[_vm._v("\n          Collaborate with your colleagues, clients or community of volunteers in an efficient way.\n          Manage language permissions and assign roles for better access control.\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          There is no limit to the amount of people you can add to collaborate - invite everyone!\n        ")])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }