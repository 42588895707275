
import Vue from 'vue';
import { LocButton } from '@localazy/components';
import LineSimple from '@/assets/svg/scribbles/LineSimple.vue';

export default Vue.extend({
  name: 'TrustedSecure',
  components: {
    LocButton,
    LineSimple
  }
});
