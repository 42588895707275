
import Vue from 'vue';
import { LocEmoji } from '@localazy/components';

const cssVar = (value: string) => `var(--${value})`;

export default Vue.extend({
  name: 'FeaturesGridCard',
  components: {
    LocEmoji
  },
  props: {
    image: {
      type: String,
      default: '547b8d0a-541e-48ee-b84c-878312585942'
    },
    title: {
      type: String,
      default: 'Bring your team together'
    },
    emoji: {
      type: String,
      default: '🤝'
    },
    column: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    iconBottom: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'accent'
    }
  },
  computed: {
    cardColor(): string {
      if (this.color === 'accent') {
        return cssVar(`${this.color}-lighten-4`);
      }
      return cssVar(`${this.color}-lighten-5`);
    }
  }
});
